import { DropdownModel } from '../geral/DropdownModel';
import _ from 'lodash';
export class ServicoManutencaoModel {
  constructor({
    id,
    codigo,
    nome,
    descricao,
    tipoServico = 2,
    tipoCalibracaoId,
    localExecucaoId,
    participanteLaboratorioId,
    amplitudeMinima,
    amplitudeMaxima,
    unidadeMedidaId,
    minimoPontos,
    tempoExecucao,
    valorServico,
    valorPonto,
    valorHora,
    desconto,
    servicoInstrumentos = [],
    modoExibicaoDescricaoContrato,
    unidadeMedida,
    localExecucao,
    participanteLaboratorio,
  }) {
    this.id = id;
    this.codigo = codigo;
    this.nome = nome;
    this.descricao = descricao;
    this.tipoServico = tipoServico;
    this.tipoCalibracaoId = tipoCalibracaoId;
    this.localExecucaoId = localExecucaoId;
    if(localExecucao)this.localExecucaoId = new DropdownModel(localExecucao);
    this.participanteLaboratorioId = participanteLaboratorioId;
    if(participanteLaboratorio)this.participanteLaboratorioId = new DropdownModel(participanteLaboratorio);
    this.amplitudeMinima = amplitudeMinima;
    this.amplitudeMaxima = amplitudeMaxima;
    this.unidadeMedidaId = unidadeMedidaId;
    if(unidadeMedida) this.unidadeMedidaId = new DropdownModel(unidadeMedida)
    this.minimoPontos = minimoPontos;
    this.tempoExecucao = tempoExecucao;
    this.valorServico = valorServico;
    this.valorPonto = valorPonto;
    this.valorHora = valorHora;
    this.desconto = desconto;
    this.servicoInstrumentos = servicoInstrumentos;
    this.modoExibicaoDescricaoContrato = modoExibicaoDescricaoContrato;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.unidadeMedidaId = retorno.unidadeMedidaId?.value;
    retorno.localExecucaoId = retorno.localExecucaoId?.value;
    retorno.participanteLaboratorioId = retorno.participanteLaboratorioId?.value;
    retorno.servicoInstrumentos = this.servicoInstrumentos.map((item) => {
     return {
      tipoInstrumentoId : item.tipoInstrumento.id,
      modeloInstrumentoId : item.modeloInstrumento?.id
     }
    });
    return retorno;
  }
}
