<template>
  <div>
    <v-form ref="form">
      <div class="row mt-3">
        <input-text
          v-model="form.codigo"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.codigo')"
          :placeholder="
            $t('modulos.servico_manutencao.formulario.codigo_placeholder')
          "
          :error="error.codigo.status"
          :error-messages="error.codigo.message"
          :max="20"
          trim
          obrigatorio
        />
        <input-text
          v-model="form.nome"
          class="col-12 col-md-8"
          :label="$t('modulos.servico_manutencao.formulario.nome')"
          :placeholder="
            $t('modulos.servico_manutencao.formulario.nome_placeholder')
          "
          :max="150"
          trim
          obrigatorio
        />
        <input-select
          v-model="form.modoExibicaoDescricaoContrato"
          :options="opcoes.tipoExebicao"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_calibracao.formulario.tipo_exibicao')"
          obrigatorio
        />
        <input-select
          v-model="form.localExecucaoId"
          :label="$t('modulos.servico_manutencao.formulario.local_execucao')"
          class="col-12 col-md-2"
          :options="opcoes.localExecucao"
          retornar-objeto
          obrigatorio
        />
        <input-laboratorio
          v-model="form.participanteLaboratorioId"
          :label="$t('modulos.servico_manutencao.formulario.laboratorio')"
          class="col-12 col-md-2"
          :multiplos="false"
          :flag-fornecedor-laboratorio="true"
        />
        <input-text
          v-model="form.minimoPontos"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.minimo_pontos')"
          type="number"
          obrigatorio
        />
        <input-unidade-medida
          ref="inputUnidadeMedida"
          v-model="form.unidadeMedidaId"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.unidade_medida')"
          :multiplos="false"
          :obrigatorio="unidadeAmplitudeEhObrigatorio"
          :error="error.unidade_medida.status"
          :error-messages="error.unidade_medida.message"
          prevent-acoes
          @prevent-abrir-modal="abrirModalUnidadeMedida"
        />
        <v-form
          ref="amplitudeForm"
          class="col-12 col-md-4 d-flex"
        >
          <input-text
            v-model="form.amplitudeMinima"
            class="flex-fill"
            :label="$t('modulos.servico_manutencao.formulario.amplitude')"
            :placeholder="
              $t('modulos.servico_manutencao.formulario.amplitude_min')
            "
            :obrigatorio="unidadeAmplitudeEhObrigatorio"
            :error="error.amplitude_maxima.status"
            :regras-personalizadas="regraAmplitude()"
            :disabled="desabilitado"
            type="number"
          />
          <div class="mx-2 mt-12">
            a
          </div>
          <input-text
            v-model="form.amplitudeMaxima"
            class="flex-fill mt-6"
            :placeholder="
              $t('modulos.servico_manutencao.formulario.amplitude_max')
            "
            :obrigatorio="unidadeAmplitudeEhObrigatorio"
            :regras-personalizadas="regraAmplitude()"
            :error="error.amplitude_maxima.status"
            :error-messages="error.amplitude_maxima.message"
            :disabled="desabilitado"
            type="number"
          />
        </v-form>
        <input-money
          v-model="form.valorServico"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.valor_servico')"
          :max="19"
          obrigatorio
        />
        <input-money
          v-model="form.valorPonto"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.valor_ponto')"
          :max="19"
          obrigatorio
        />
        <input-money
          v-model="form.valorHora"
          class="col-12 col-md-2"
          :label="$t('modulos.servico_manutencao.formulario.valor_hora')"
          :max="19"
          obrigatorio
        />
        <input-money
          v-model="form.tempoExecucao"
          class="col-12 col-md-3 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.tempo_execucao')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.tempo_execucao')
          "
          :options="optionsTempoDesconto"
        />
        <input-money
          v-model="form.desconto"
          class="col-12 col-md-3 pb-0"
          :label="$t('modulos.servico_calibracao.formulario.desconto')"
          :placeholder="
            $t('modulos.servico_calibracao.formulario.desconto_placeholder')
          "
          :options="optionsTempoDesconto"
        />
        <input-textarea
          v-model="form.descricao"
          class="col-12 col-md-12 pt-0"
          :label="$t('modulos.servico_calibracao.formulario.descricao')"
          :max="1000"
          trim
          obrigatorio
        />
      </div>
    </v-form>
  </div>
</template>
<script>
import {
  InputLaboratorio,
  InputUnidadeMedida,
  InputMoney,
} from '@components/inputs';
import LocalExecucaoService from '@common/services/cadastros/LocalExecucaoService';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  components: {
    InputLaboratorio,
    InputUnidadeMedida,
    InputMoney,
  },
  props: {
    form: {},
    erros: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      optionsTempoDesconto: {
        locale: 'pt-BR',
        prefix: null,
        suffix: '',
        precision: 2,
      },
      mensagem: null,
      desabilitado: true,
      opcoes: {
        localExecucao: [],
        tipoExebicao: [],
      },
      error: {
        codigo: {
          status: false,
          message: '',
        },
        amplitude_maxima: {
          status: false,
          message: '',
        },
        unidade_medida: {
          status: false,
          message: '',
        },
      },
    };
  },
  computed: {
    unidadeAmplitudeEhObrigatorio: function () {
      if (this.form.unidadeMedidaId) return true;
      if (this.form.amplitudeMaxima) return true;
      if (this.form.amplitudeMinima) return true;
      return false;
    },
  },
  watch: {
    erros: {
      deep: true,
      handler(value) {
        if (value.includes('codigoJaUtilizado')) {
          this.error.codigo = {
            status: true,
            message: this.$t(
              'modulos.servico_manutencao.formulario.erros.codigo_existente'
            ),
          };
        }
        if (value.includes('amplitudeMaximaDeveSerMaiorQueAmplitudeMinima')) {
          this.error.amplitude_maxima = {
            status: true,
            message: this.$t(
              'modulos.servico_manutencao.formulario.erros.amplitude_maxima'
            ),
          };
        }
        if (value.includes('preencherUnidadeMedidaId')) {
          this.error.unidade_medida = {
            status: true,
            message: this.$t(
              'modulos.servico_manutencao.formulario.erros.unidade_medida'
            ),
          };
        }
      },
    },
    form: {
      deep: true,
      handler() {
        if (this.erros.length) {
          this.resetErrors();
          this.$emit('clear-errors');
        }
      },
    },
    'form.unidadeMedidaId': {
      deep: true,
      handler() {
        if (this.form.unidadeMedidaId) {
          this.desabilitado = false;
        }
      },
    },
    'form.amplitudeMinima': {
      deep: true,
      handler() {
        this.validarAmplitude();
        this.mensagem = this.$t(
          'inputs.rules.amplitudeMinimaNaoPodeSerMaiorQueAmplitudeMaxima'
        );
      },
    },
    'form.amplitudeMaxima': {
      deep: true,
      handler() {
        this.validarAmplitude();
        this.mensagem = this.$t(
          'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
        );
      },
    },
  },
  mounted() {
    this.listarLocalExecucao();
    this.listarTipoExibicao();
  },
  methods: {
    regraAmplitude: function () {
      if (!this.form.amplitudeMaxima || !this.form.amplitudeMinima) return [];

      let regraValor = (v) => {
        let valor = `${v}`;
        let intValue = valor.split('.')[0];
        let decimalValue = valor.split('.')[1];
        if (intValue.length > 10) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            char: 10,
            decimal: 9,
          });
        }

        if (decimalValue && decimalValue.length > 9) {
          return this.$t('inputs.rules.maxBetweenDecimalValue', {
            decimal: 9,
            char: 10,
          });
        }
        return true;
      };

      let regraValorMaior = () =>
        parseFloat(this.form.amplitudeMaxima) >=
        parseFloat(this.form.amplitudeMinima)
          ? true
          : this.$t(
              'inputs.rules.amplitudeMaximaNaoPodeSerMenorQueAmplitudeMinima'
            );
      return [regraValor, regraValorMaior];
    },
    listarLocalExecucao: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalExecucaoService.listar({amountPerPage: 1000, page: 1})
        .then((res) => {
          this.opcoes.localExecucao = new DropdownModel(res.data);
        })
        .catch(() => {
          this.opcoes.localExecucao = [];
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarTipoExibicao: function () {
      EnumeradorService.buscar('EnumModoExibicaoDescricaoContrato').then(
        (res) => {
          this.opcoes.tipoExebicao = res;
        }
      );
    },
    resetErrors() {
      this.error.codigo.status = false;
      this.error.codigo.message = '';
      this.error.amplitude_maxima.status = false;
      this.error.amplitude_maxima.message = '';
      this.error.unidade_medida.status = false;
      this.error.unidade_medida.message = '';
    },
    validarAmplitude() {
      if (!this.form.amplitudeMinima && !this.form.amplitudeMaxima) return;
      this.$refs.amplitudeForm.validate();
    },
    abrirModalUnidadeMedida: function () {
      // if (!this.form.unidadeMedidaId) {
        this.$refs.inputUnidadeMedida.abrirModal();
      //   return;
      // }

      // this.confirmar(
      //   this.$t('geral.titulos.atencao'),
      //   this.$t(
      //     'modulos.servico_calibracao.formulario.confirmar_alteracao_dados_unidade_medida'
      //   )
      // ).then(() => {
      //   this.$refs.inputUnidadeMedida.abrirModal();
      // });
    },
  },
};
</script>
