<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab>
        {{ $t('modulos.servico_manutencao.formulario.abas.dados_servico') }}
      </v-tab>
      <v-tab>
        {{
          $t(
            'modulos.servico_manutencao.formulario.abas.tipo_modelo_instrumento'
          )
        }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAba">
      <v-tab-item>
        <dados-servico
          ref="dados-servico"
          :form="form"
          :erros="erros"
        />
      </v-tab-item>

      <v-tab-item>
        <tipo-modelo-instrumento
          ref="tipo-modelo-instrumento"
          :form="form"
        />
      </v-tab-item>
    </v-tabs-items>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { ServicoManutencaoModel } from '@common/models/cadastros/ServicoManutencaoModel';
import ServicoService from '@common/services/cadastros/ServicoService';
import helpers from '@common/utils/helpers';
import DadosServico from './components/DadosServico';
import TipoModeloInstrumento from './components/TipoModeloInstrumento';
export default {
  components: {
    DadosServico,
    TipoModeloInstrumento,
  },
  props: ['id'],
  data() {
    return {
      erros: [],
      valido: false,
      indiceAba: 0,
      form: new ServicoManutencaoModel({}),
      opcoes: {
        boleano: helpers.BoleanoEnum,
      },
      watchers: {
        unidadeMedida: null,
        amplitudeMinima: null,
        amplitudeMaxima: null,
      },
      usuarioNotificado: false,
      evitarLoopWatcher: false,
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.servico_manutencao.titulos.editar');
      return this.$t('modulos.servico_manutencao.titulos.novo');
    },
  },
  mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'ServicoManutencao', 'Editar');
      this.buscar(this.id);
    } else {
      helpers.redirecionarSemPermissao(this, 'ServicoManutencao', 'Inserir');
      this.definirWatchers();
    }
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ServicoService.buscar(this.id)
        .then((res) => {
          this.form = new ServicoManutencaoModel(res.data);
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.servico_manutencao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.definirWatchers();
        });
    },
    definirWatchers() {
      if (this.watchers.unidadeMedida) this.watchers.unidadeMedida();
      // if (this.watchers.amplitudeMinima) this.watchers.amplitudeMinima();
      // if (this.watchers.amplitudeMaxima) this.watchers.amplitudeMaxima();

      // this.watchers.unidadeMedida = this.$watch(
      //   'form.unidadeMedidaId',
      //   () => this.form.servicoInstrumentos = []
      // );
      // this.watchers.amplitudeMinima = this.$watch(
      //   'form.amplitudeMinima',
      //   (novo, antigo) =>
      //     this.avisoTrocaUnidadeMedidaOuAmplitude(
      //       novo,
      //       antigo,
      //       'amplitudeMinima'
      //     )
      // );
      // this.watchers.amplitudeMaxima = this.$watch(
      //   'form.amplitudeMaxima',
      //   (novo, antigo) =>
      //     this.avisoTrocaUnidadeMedidaOuAmplitude(
      //       novo,
      //       antigo,
      //       'amplitudeMaxima'
      //     )
      // );
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    avisoTrocaUnidadeMedidaOuAmplitude(novo, antigo, campo) {
      if (this.evitarLoopWatcher) {
        this.evitarLoopWatcher = false;
        return;
      }
      if (this.usuarioNotificado) return;
      if (novo === antigo) return;
      if (!this.form.servicoInstrumentos.length) return;

      this.confirmar(
        this.$t('modulos.servico_manutencao.alteracao_na_faixa'),
        this.$t(
          'modulos.servico_manutencao.aviso_alterar_unidade_medida_amplitude'
        )
      )
        .then(() => {
          this.form.servicoInstrumentos = [];
          this.usuarioNotificado = true;
        })
        .catch(() => {
          this.form[campo] = antigo;
          this.evitarLoopWatcher = true;
        });
    },
    salvar: function () {
      if (this.$refs['dados-servico']) {
        this.valido = this.$refs['dados-servico'].$refs.form.validate();
      }
      if (!this.valido) {
        this.indiceAba = 0;
        return;
      }
      if (this.form.tempoExecucao)
        this.form.tempoExecucao = Number(this.form.tempoExecucao);
      if (this.form.desconto) this.form.desconto = Number(this.form.desconto);
      this.$store.dispatch('Layout/iniciarCarregamento');
      ServicoService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'servico-manutencao' });
          this.toastSucesso(
            this.$t(`modulos.servico_manutencao.cadastro_sucesso`)
          );
        })
        .catch((err) => {
          this.erros = err.response.data.errors;
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'servico-manutencao' });
      });
    },
  },
};
</script>
